.project-header{
    padding: 0em 4em;
    margin: 0 auto;
}
.project-header h1{
    font-weight: 600;
    font-size: 2.5rem;
    width: 60%;
    margin: 0;
}
.project-header-links ul{
    display: flex;
    gap: 2em;
    padding: 0;
    
}
.project-header-links ul li{
    padding: .5em 2em;
    border: 1px solid black;
    border-radius: 4px;
    list-style-type: none;
}
.project-header-links ul li.active{
    border: 1px solid rgba(22, 22, 235, 1);;
    
}
.project-header-links ul li a.active{
    color: rgba(22, 22, 235, 1);
    font-weight: 500;

}
.project-header-links ul li a{
    text-decoration: none;
    font-size: 1.45rem;
    color: black;
}
@media only screen and (max-width: 480px) {
   
    .project-header h1{
        width: 100%;

    }
    .project-header-links ul li{
        padding: .5em;
    }
    .project-header-links ul{
        display: grid;
        grid-template-columns: repeat(2,1fr)
    }

  
}

@media only screen and (max-width: 768px) {
    .project-header h1{
        width: 100%;

    }
    .project-header{
        padding: 0em 2em;
    }
    .project-header-links ul{
        display: grid;
        grid-template-columns: repeat(2,1fr)
    }
}