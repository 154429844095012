.community_container{
background: rgba(232, 232, 253, 1);
display: flex;
    justify-content: center;
    
    align-items: center;
}
.community_content{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6em 0em
}
.community_content_top{
font-size: 2.5rem;
font-weight: 700;
letter-spacing: 0em;
text-align: center;
color: rgba(22, 22, 235, 1);
margin: 0;

}
.community_content_middle{
    margin: 0;
    font-size: 1.45rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    padding: 0em 0em 1em;
}

@media only screen and (max-width: 480px) {
    .community_content{
        padding: 1em 1em;
        width: 100%;
    }
  
}