
.header_nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 4em 4em;
    margin: 0 auto;
}
nav ul{
    list-style-type: none;
    display: flex;
    gap: 2em;
    margin: 0;
}
nav ul li a {
    text-decoration: none;
    font-size: 1.2rem;
    color: black;
    position: relative;
    font-weight: 500; 
    transition: transform 0.3s ease-in, font-weight 0.6s ease-in;
}
nav ul li a.active:before{
    position: absolute;
    content: "";
    width: 7px; 
    height: 7px; 
    background-color: rgba(22, 22, 235, 1); 
    top: 50%; 
    transform: translateY(-50%);
    left: -13px; 
    border-radius: 90%; 
    opacity: 1;
}
nav ul li a.active {
    color: rgba(22, 22, 235, 1);
    font-weight: 500;
    position: relative; 
}
nav ul li a:before{
    position: absolute;
    content: "";
    width: 7px; 
    height: 7px; 
    background-color: rgba(22, 22, 235, 1); 
    top: 10%; 
    transform: translateY(-50%); 
    left: -13px; 
    border-radius: 90%; 
    transition: width 0.3s ease-in, top 0.3s ease-in;
    opacity: 0;
   
}

nav ul li a:hover:before{
   
    top: 50%; 
    opacity: 1; 
}
nav ul li a:hover{
    color: rgba(22, 22, 235, 1);
    font-weight: 500; 
    transform: scale(1.2)
}
.sidebar{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    background-color:rgba(255, 255, 255, 0.8);
    box-shadow: -10px 0 10px black;
    display: none;
    backdrop-filter: blur(10px);
    flex-direction: column;
    align-items: flex-start;
     justify-content: flex-start;
    padding: 1em 0em;
    
}
.sidebar li{
    width: 100%;
    padding: 0em 4em;
}
.sidebar a{
    width: 100%;

}
.menu-button{
    display: none;
}
@media only screen and (max-width: 480px) {
    nav ul{
        flex-direction: column;
    }
    .header_nav{
        align-items: flex-start;
        max-width: 360px;
    }
    .hideOnMobile{
        display: none
    }
    .menu-button{
        display: block;
    }
    .header_nav{
         padding: 2em 2em 4em;
    }

}

