.value-box {
    display: flex; 
    padding: 2em 0em;
}

.value-grid {
    flex: 1; 
    margin-right: 20px; 
    padding: 1em 2em;
    background: rgba(250, 250, 250, 1);
    border-radius: 4px;
}
.value-grid:hover{
    background: rgba(232, 232, 253, 1);
    
}
.value-grid:last-child {
    margin-right: 0; 
}

.value-grid h3,
.value-grid p {
    text-align: left; 
}
.value-grid p {
    width: 87%
}
.value-grid h3{
    font-size: 1.5rem;
    margin:0;
}
.value-grid img{
    width: 100%;
}

.value-header{
    text-align: center;
    padding: 6em 0em 2em;
}
.value-header h1{
    font-weight: 500;
    font-size: 3.6rem;
}
.value-header p{
    margin: 0;
}
#bottom-hero{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    height: 714px; 
    background: url("../../../assets/connectbtm.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.btm-hero-txt{
    font-weight: 500;
    font-size: 3.5rem;
    padding: 0em 0em 0em;
    color: #ffffff;
    text-align: center;
    margin: 0;
}
.value-content{
    padding: 0em 0em 6em
}

@media only screen and (max-width: 768px) {
    .value-box{
        flex-direction: column
    }
    
}

@media only screen and (max-width: 480px) {
    .value-header h1{
       
        font-size: 2.5rem;
    }
    #bottom-hero{
              padding: 1em;
    }
    .value-box{
        flex-direction: column;
        gap: 1.5em
    }
    .value-grid {
        
        margin-right: 0px; 
    }
    
    .value-grid p {
        width: 100%
    }
    .btm-hero-txt{
        
        font-size: 3rem;
    }

}

