.next-project-container{
 
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        padding: 5em 0em;
        background: #000000
}
.next-project-container h5{
    margin: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 1.45rem;
}
.next-project-container h3{
    margin: 0;
    font-size: 2.5rem;
    color: #ffffff;
    font-weight: 600;
}

@media only screen and (max-width: 480px) {
   
    .next-project-container img{
      width: 100%
    }
  
}
@media only screen and (max-width: 768px) {
   
    .next-project-container img{
      width: 100%
    }
    .next-project-img{
        width: 80%;
    }
  
}