.join-comm-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding: 4em ;
}
.join-comm-container h4{
    margin: 0;
    font-size: 2.1rem;
}
.join-comm-text1{
    margin:0;
    width: 58%;
    text-align: center;
}
.join-comm-btn{
padding: .5em 1em;
background-color: #000000;
color: #ffffff;
border-radius: 4px;
text-decoration: none;
}
.join-comm-btn:hover{
    background-color: rgba(183, 183, 249, 1);
    transition: .5s ease-in;
}
    
@media only screen and (max-width: 480px) {
   
    .join-comm-container h4{
      text-align: center;
    }
    .join-comm-text1{
        width: 100%;
    }
  
}
@media only screen and (max-width: 768px) {
   
    .join-comm-container h4{
      text-align: center;
    }
    .join-comm-text1{
        width: 100%;
    }
  
}