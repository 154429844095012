.about-hello {
    display: flex;
    gap: 1.5em;
}
.about-hello p{
    font-weight: 500;
}
.about-hello img{
    object-fit: contain;
}
.about-hello-text{
    
    font-size: 3.80rem;
    font-weight: 500;
    width: 73%;
    margin: 0;
    padding: 0em 0em .5em;
}
.about-hero-img{
    width: 100%;
}
.about-text-one{
    font-size: 1.5rem;
    font-weight: 500;
}
@media only screen and (max-width: 1024px) {
    .about-hello-text{
        width: 100%;
        font-size: 2.6rem;
    }
}
@media only screen and (max-width: 768px) {
    .about-hello-text{
        width: 100%;
        font-size: 2.6rem;
    }
    .about-text-one{
        font-size: 1.2rem;
    }
}
@media only screen and (max-width: 480px) {
    .about-hello-text{
        width: 100%;
        font-size: 2.5rem;
        
    }

  
}