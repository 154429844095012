.project_container{
    margin: 0 auto;
  
    padding: 4em
}
.header_project{
    display: flex;
    justify-content: center;
    align-items: center;
}
.header_project h1{
    margin: 0;
    font-size: 2.5rem;
}
.project_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 0em;
}

.project_content_left p{
    margin: 0
}
.project_content_left{
    width: 49%;
    display: flex;
    flex-direction: column;
    gap:1em;
    text-decoration:none;
}
.project_content_left img{
    width: 100%;
}

.project_content_box{
display: flex;
gap: .5em;
align-items: center;
}
.project_content_text1{
padding: .2em;
border-radius: 16px;
border: 1px solid black;
color: #000000
}

.project_content_text{
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0;
    color: #000000
}

@media only screen and (max-width: 480px) {
    .project_content{
        flex-direction: column;
        gap: 1em;
    }
    .project_container{
        
        padding: 4em 2em;
    }
    .project_content_left{
        width: 100%;
    
    }
  
}
