.tos-container{
    padding: 0em 4em;
}
.tos-container h1{
    margin: 0;
    font-weight: 500;
    font-size: 57px;
  
}
.tos-content h5{
    margin: 0;
    font-weight: 500;
    font-size: 23px;
}
.tos-content p{
    margin: 0;
}
.tos-content{
    padding: 2em 0em;
}