.about-mission{
    display: grid;
    column-gap: 3em;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2em;
}
.mission-grid{
    
    padding: 1em 2em;
    background: rgba(250, 250, 250, 1);
    border-radius: 16px;
}
.mission-grid:hover{
    background: rgba(232, 232, 253, 1);
    border: 1px solid rgba(69, 69, 239, 1)
}
.mission-grid h3{
    font-size: 1.5rem;
    
}
.mission-grid p{
    width: 90%;
    margin: 0;
}
@media only screen and (max-width: 768px) {
    

    .about-mission{
        
        grid-template-columns: repeat(1, 1fr);
      
    }
    
}
@media only screen and (max-width: 480px) {
    
  
}