#root {
  margin: 0 auto;
}


/* @keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
} */
@media only screen and (max-width: 480px) {
  
  #root {
    margin: 0 auto;
  
  }
}

