
.coming-soon{
    margin: 0 auto;
    padding: 2em 4em 4em;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background: #ffffff
}
.coming-soon-content{
    width: 33%;
    z-index: 2;
    padding: 2em 0em;
    position: relative;
}
.coming-soon-content h1{
    text-align: center;
    font-size: 3rem;
    font-weight: 500;
    margin: 0;
    
}
.coming-soon-product p{
   text-align: center; 
   margin: 0;
}
.coming-soon-product{
    padding: 1em 0em 0em;
}

.product-sub-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.product-sub-input{
   
        width: 315px;
        height: 34px;
        background-color: rgba(245, 245, 245, 1);
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius:4px;
        color: #000000;
        outline: none;
        padding: 0em 1em;

}
.product-sub-btn{
    
        width:93px;
        color: #ffffff;
        height: 34px;
        border-radius: 4px;
        background: #000000;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
}

.moving-image {
    position: relative;
    top: 0;
    left: 100%; 
    animation: moveImage 10s linear infinite; 
}
.status-message{
    padding: 2em 0em;
}
.error-message{
    padding: 1em 0em 0em;
    margin: 0;
}


@keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 60px 0;
    white-space: nowrap;
  }
  .logos-slide{
      display: inline-block;
     
      animation: 10s slide infinite linear;
  }
  .logos-slide img {
   
    margin: 0 -8px;
  }
  .product-sub-success{
    color: #000000;
  }
  

  @media only screen and (max-width: 1024px) {


    .coming-soon-content{
        width: 60%;
    }
    .logos-slide img{
        height: 100%
    }
   
}

@media only screen and (max-width: 768px) {


    .coming-soon-content{
        width: 80%;
    }
    .logos-slide img{
        height: 200px;
    }
   
}


@media only screen and (max-width: 480px) {
    .coming-soon-content{
        width: 100%;
    }
    .logos-slide img{
        height: 130px;
    }
    .product-sub-container{
        flex-direction: column;
    }
    .coming-soon{
        padding: 0em 2em 4em;
    }
    .coming-soon-content h1{
        font-size: 2rem;
    }
}