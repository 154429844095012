.contact-container{
padding: 0em 4em 4em;
}
.contact-container h1{
font-size: 57px;
font-weight: 500;
margin: 0;
}
.contact-container p{
    font-size: 19px;
    margin: 0;
}
.contact-form{
    display: flex;
    padding: 4em 0em 2em;
    gap: 1em
}
.contact-text{
    width: 50%;
}
.contact-text input{
    border: none;
    outline: none;
    border-bottom:1px solid rgba(96, 96, 96, 1);
    width: 100%;
    padding: 0em 0em .5em;
}
.contact-text h4{
    font-size: 1.2rem;
    margin: 0;
    font-weight: 500;
}

textarea{
    background: rgba(245, 245, 245, 1);
    border:none;
    outline: none;
    border-radius: 4px;
    width: 100%;
    padding: 1em;
}
textarea::placeholder{
    font-family: 'Libre Franklin', system-ui, Avenir, Helvetica, Arial, sans-serif;
  }

.contact-message{
    padding: 0em 0em 2em ;
}
.contact-message h4{
    font-size: 1.2rem;
    font-weight: 500;
}
.modal-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5em 1em;
}
.modal-content h4{
 margin: 0;
 font-size: 1.2rem;
 font-weight: 500;
}
.modal-content p{
    margin: 0;
    text-align: center;
    width: 75%;
   }
.modal-top-img{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content-top{
    padding: 0.2em 1em;
    background-color: rgba(232, 232, 253, 1);
}
.modal-close{
    display: flex;
    justify-content: end;
  
}
@media only screen and (max-width: 480px) {
    .contact-container{
        padding: 0em 2em 4em;
        }
  
}