.details-content-container{
padding: 1.5em 4em;
}
.details-content-left-align{
    padding: 2em 4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(245, 245, 245, 1);
    height: auto;
}
.details-content-text{
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: justify;
    justify-content: center;
}
.details-content-text h4{
    margin: 0;
}
.details-content-text p{
    margin: 0;
}
.spacer-content{
    padding: 1em 0em;
}

.details-content-left-align img{
    width: 100%
}

@media only screen and (max-width: 480px) {
   

    .details-content-left-align{
       flex-direction: column;
       gap:.5em
    }
    .details-content-text{
        width: 100%;
    }
  
}
@media only screen and (max-width: 768px) {
   

    .details-content-left-align{
      padding: 1em;
      gap: 2em;
    }
    .details-content-text{
        width: 100%;
    }
    
}