.details-container{
    margin: 0 auto;
    padding: 0em 4em;
}
.details-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0em 0em 2em
}
.details-header h1{
    margin: 0;
    font-size: 2.5rem;
    font-weight: 600;
}
.details-header p{
    margin: 0;
    font-size: 1.45rem;
    padding: .5em 0em 1em;
}
.details-header a{
    padding: .5em 1em;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    background-color: black;
}

.details-header a:hover{
background-color: rgba(183, 183, 249, 1);
transition: .5s ease-in;
}
.details-img{
    width: 100%
}


@media only screen and (max-width: 480px) {
   
    .details-header p{
        text-align: center;
    }
  
}