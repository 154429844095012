.grid-container {
    
    margin: 0 auto;
    padding: 2em 4em;
  }

.grid-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2em;
    row-gap: 2em;
    padding: 0em 0em 2em;
 
  }
.project_grid_left p{
    margin: 0
}
.project_grid_left{
    display: flex;
    flex-direction: column;
    gap:1em;
}
.project_grid_left img{
    width: 100%;
}
project_grid_text1{
    font-weight: 400;
}
.project_grid_box{
display: flex;
gap: .5em;
align-items: center;
}
.project_grid_text1{
padding: .2em;
border-radius: 16px;
border: 1px solid black
}

.project_grid_text{
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0;
}
.paginator-box{
    display: flex;
    justify-content: center;
    align-items: center;
}
.paginator-box-go{
    display: flex;
    width: 300px;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.paginator-box p{
    margin: 0;
}
.paginator-input{
    width: 3%;
    padding: .5em 1em;
    font-weight: 500;
    outline: none;
    color: rgba(69, 69, 239, 1);
    border-radius: 4px;
    border: 2px solid rgba(69, 69, 239, 1);
    font-size: 1rem
}
.paginator-btn{
    color: rgba(69, 69, 239, 1);
    border-radius: 4px;
    border: 2px solid rgba(69, 69, 239, 1);
    padding: .4em 2em;
    font-weight: 500;
}
.paginator-box-arrow{
display: flex;
gap: .5em;
}
@media only screen and (max-width: 480px) {
   
    .grid-content{
        grid-template-columns: repeat(1, 1fr);
      }
    .paginator-box{
      flex-direction: column;
      gap: 1em
    }
    .grid-container {
        padding: 2em 2em;
      }
}

@media only screen and (max-width: 768px) {
    .project_grid_box{
        flex-direction: column;
        align-items: flex-start
        }
     
}