.data-request{
    padding: 2em 4em;
}
.data-request-header{
  text-align: center;
}
.data-request-header p{
    margin: 0;
}


.data-request h3{
    font-weight: 500;
    font-size: 19px;
}
.data-input-one{
    width: 100%;
    border: 1px solid black;
    height: 56px;
    outline: none;
    border-radius: 4px;
    padding: 0em 1em;
    box-sizing: border-box;
    font-size:1em;
    font-family: 'Libre Franklin', system-ui, Avenir, Helvetica, Arial, sans-serif;
}

input[type='radio']{
    accent-color:blue;
}

input[type='checkbox'] {
    accent-color:blue;
}

.custom-select-container {
    position: relative;
    width: 100%;
    cursor: pointer;
  }
  
  .selected-option {
   padding: 1em;
   border: 1px solid;
   border-radius: 4px;
   display: flex;
   justify-content: space-between;
  }
  
  .options {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 1;
  }
  
  .options li {
    padding: 10px;
  }
  
  .options li:hover {
    background-color: rgba(232, 232, 253, 1);
  }
  
  .selected-option-list{
    background-color: rgba(22, 22, 235, 1);
    color: #fff;
    display: flex;
    gap: .5em;
  }
  
  .data-textarea{
    font-size: 1em;
    font-family: 'Libre Franklin', system-ui, Avenir, Helvetica, Arial, sans-serif;
  }

.welcome-head p{
    border: 1px solid black;
    border-radius: 4px;
    padding: 1em;
}
.data.btn{
    padding: 2em 0em 0em;
    display: flex;
    justify-content: flex-end;
}
@media only screen and (max-width: 480px) {
    
    .data-request{
        padding: 2em 2em;
    }
}