.faq-content{

    margin: 0 auto;
    padding: 6em 4em;
}
.accordion-item{
    padding: .5em 0em;

}
.accordion-header{
    display: flex;
    justify-content: space-between;
    padding: 1.2em ;
    background: rgba(232, 232, 253, 1);
}

.accordion-question{
    font-size: 1.45rem;
    font-weight: 700;
}

.accordion-content{
    overflow: hidden;
    max-height: 0px;
    font-size: 1.45rem;
    transition: max-height 0.5s ease;
    padding: 0em 1em;
    background: rgba(232, 232, 253, 1);
}
.accordion-content p{
    font-size: 1.2rem;
}
.accordion-content.open{
    max-height: 150px;
    font-size: 1.2rem;
    transition: all 0.5s ease-in;
    padding: 0em 1em 0.5em ;
}
.accordion-icon {
    cursor: pointer;
    font-size: 1.45rem;
}



.plus-sign {
    position: relative;
    width: 23px; 
    height: 23px; 
  }
  
  .horizontal-line,
  .vertical-line {
    position: absolute;
    background-color: black; 
  }
  
  .horizontal-line {
    width: 100%;
    height: 2px; 
    top: 50%;
    transform: translateY(-50%);
  }
  
  .vertical-line {
    width: 2px; 
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .vertical-line.open{
    transform: rotate(90deg);
    transition:  0.3s ease;
  }
  .vertical-line.close {
    transition:  0.3s ease;
  }
  @media only screen and (max-width: 480px) {

    .faq-content{
        max-width: 360px;
        padding: 6em 2em;
    }
    .accordion-content.open{
        max-height: 500px;
        text-align: justify;
     
    }
    
  
}