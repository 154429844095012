.hero_section{
    margin: 0 auto;
    padding: 0em 4em;
    display: flex;
    flex-direction: column;
}
.hero_section p{
    font-size: 2.5rem;
    font-weight: 600;
    width: 70%;
    margin: 0;
}
.hero_section img{
    background: rgba(22, 22, 235, 1);
   object-fit: contain;
   height: 464px;
}
@media only screen and (max-width: 480px) {
    .hero_section p{
        width: 100%;
        text-align: center;
    }
    .hero_section{
        
        padding: 0em 2em;
    }

  
}

@media only screen and (max-width: 768px) {
    .hero_section p{
        width: 100%;
    }

}

