:root {
  font-family: 'Libre Franklin', system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  /* color: #000000;
  background-color: #242424; */
  /* font-synthesis: none; */
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

input{
  font-family: 'Libre Franklin', system-ui, Avenir, Helvetica, Arial, sans-serif;
}
input::placeholder{
  font-family: 'Libre Franklin', system-ui, Avenir, Helvetica, Arial, sans-serif;
}

/* To remove the up and down arrow decrement buttons */

/* For webkit-based browsers */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Optional - if you want to remove the margin */
}

/* For Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* For all browsers (optional) */
input[type=number] {
  appearance: textfield; /* Fallback for other browsers */
}

body {
  margin: 0;
  min-height: 100vh;
}
button{
  width:168px;
  height: 56px;
  font-size: 16px;
  font-weight: 700;
  padding: 16px, 24px, 16px, 24px;
  border-radius: 4px;
  color:  rgba(22, 22, 235, 1);
  background:inherit;
  border: 1px solid rgba(183, 183, 249, 1);
}
button:hover{
background-color: rgba(183, 183, 249, 1);
transition: .3s ease-in;
}


@media (prefers-color-scheme: light) {
  :root {
    /* color: #000000; */
    background-color: #ffffff;
  }
  /* a:hover {
    
  }
  button {
    background-color: #f9f9f9;
  } */
}
