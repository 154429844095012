.what_we_do_container{
margin: 0 auto;
padding: 4em;
}
.header_what_we_do{
    display: flex;
    justify-content: center;
    align-items: center;
}
.header_what_we_do h1{
    margin: 0;
    font-size: 2.5rem;
}
.what_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0em 0em;
}
.what_content_right{
    width: 49%;
}
.what_content_left p{
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
}
.what_content_left{
    width: 49%;
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.what_content_left_1{
   
    padding: 1em 2em;
    border-radius: 4px; 
    background: rgba(232, 232, 253, 1);
    align-items: center;
    display: flex;
}
.what_content_left_2{
    padding: 1em 2em;
    border-radius: 4px; 
    background: rgba(231, 231, 231, 1);
    align-items: center;
    display: flex;
}

.what_content_right{
    background: rgba(232, 232, 253, 1);
  
}
.what_content_right_1{
padding: 1.2em 2em;
width: 83%;
}
.what_content_right_p{
    margin: 0;
    font-size: 1.2rem;
    font-weight: 400;
}
.what_content_right_2{
    padding: 0.5em 1em;
}
@media only screen and (min-width: 1760px) {
    
    .what_content_right_1{
        width: 70%
    }
}
@media only screen and (max-width: 480px) {
    
    .what_we_do_container{
      
        padding: 4em 2em;
    }
    .what_content_right, .what_content_left {
        
        width: 100%;
    }
    .what_content{
       flex-direction: column;
       gap: 2em;
    }
    .what_content_right_p{
      text-align: justify;
      font-size: 1.2rem;
    }
}
