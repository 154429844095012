.no-event{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4em 0em;
}
.no-event p{
   text-align: center;
}
.no-event h2{
    font-weight: 500;
    font-weight: 1.2rem;
}