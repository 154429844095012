
.story-container{
    padding: 1.5em 4em;
}
.story-content h3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0em 0em .5em;
    margin: 0;
    font-weight: 500;
    font-size: 1.45rem;
}
.story-content{
    padding: 0em 0em 1em;
}

@media only screen and (max-width: 480px) {
   .story-content p{
       text-align: justify;
   }
  
}
