.footer_container{
    background-color: rgba(22, 22, 235, 1);
   
}
.container_footer_main{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 5em 4em 0em;
}
.input_footer_box{
    display: flex;
    gap: .5em;
    
}
.input_footer{
    width: 100%;
    height: 56px;
    background-color: rgba(22, 22, 235, 1);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius:4px;
    color: #ffffff;
    outline: none;
    padding: 0em 2em;
}
.input_footer::placeholder {
    color: #ffffff;
  }

.logo_container_footer p{
 
    font-size: 23px;
    color: #ffffff
}
.logo_container_footer {
    width: 67%;   
}
.footer_links{
    display: flex;
    justify-content: space-between;
    gap: 3em;
}
.footer_links ul{
    margin: 0;
    padding: 0;
}
.footer_links ul li {
    list-style-type: none;
    text-decoration: none;
    
}
.footer_links ul li:not(:first-child) {
    margin-top: 20px; 
}
.footer_links ul li a{
    font-size: 1.2rem;
    color: #ffffff;
    text-decoration: none;
}
.footer_social_media ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6em;
    padding: 4em 0em 1.5em 0em;
    list-style-type: none;
    margin: 0;
}
.footer_social_media ul li a{
    font-size: 1.2rem;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5em;
    border-top: 1px solid;
    width: 150px;
    padding: .5em 0em;
}

.sub-error{
    color: red;
    padding:  .5em 0em .5em;
    font-weight: 700;
    margin: 0;
}
.sub-success{
    color:white;
    padding: 1em 0em 0em;
}
.sending{
    color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
}


@media only screen and (max-width: 768px) {
    

    .footer_social_media {
        flex-direction: column;
        width: 100%;
        
    }
    .footer_social_media ul {
        gap: 1.5em;
    }
    .footer_social_media ul li a{
        width: 100%;
    }

    
}
@media only screen and (max-width: 480px) {
    .footer_social_media ul li a{ 
        width: 100%;
    }
   
    .footer_social_media ul {

        display: grid;
        grid-template-columns: repeat(2,1fr);
        padding: 4em 2em 0em 2em;
    }
    .container_footer_main{
        flex-direction: column;
        max-width: 360px;
        gap: 2em;
        justify-content: center;
        padding: 5em 2em 0em; 
    }
    .logo_container_footer{
        width: 100%;
    }
    
    .input_footer{
        width: 50%;
    }

  
}