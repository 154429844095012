.privacy-container{
    padding: 0em 4em 4em;
}
.privacy-container h1{
    font-size: 3.5rem;
    font-weight: 500;
}
.toc-privacy a{
    color: #000000
}
.toc-header{
    font-size: 1.5rem;
    font-weight: 400;
    margin: 40px 0 0px;
}
.toc-header-one{
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0;
}

.bullet-texts {
    display: list-item;  
}
.bullet-text-container{
    padding: 0em 0em 0em 1em;
}

@media only screen and (max-width: 480px) {
   
 .privacy-container{
     padding: 0em 2em 4em;
 }
 .privacy-container h1{
    font-size: 2.5rem;
   
}
  
}
